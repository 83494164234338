import { get, writable } from "svelte/store";
import * as constants from "../assets/constants.json";
export const siteStore = writable(null);
export function getSitename() {
    let site = get(siteStore);
    return (site && site.name) || constants.app_name;
}
export function getLang() {
    let site = get(siteStore);
    return (site && site.language) || "en";
}
export const userStore = writable(null);
export function getUsername() {
    let user = get(userStore);
    return (user && user.username) || "";
}
export function setUserExpire(newExpire) {
    let user = get(userStore);
    if (user) {
        user.expire = newExpire;
    }
}
export const notificationStore = writable(null);
export function setNotification(type, msg) {
    const newNotification = { type, msg };
    notificationStore.set(newNotification);
}
export const clickStore = writable(0);
export const sectionStore = writable(null);
export const dirsStore = writable([]);
export const filesStore = writable([]);
export function pushFile(file) {
    if (!file.dir)
        return;
    const currentDir = get(dirsStore);
    if (currentDir.join("/") || "/" === file.dir) {
        const newFiles = get(filesStore);
        const index = newFiles.findIndex((f) => f.filename === file.filename);
        if (index >= 0) {
            newFiles[index] = file;
        }
        else {
            newFiles.push(file);
        }
        filesStore.set(newFiles);
    }
}
export function updateFile(oldFile, newFile) {
    if (!newFile.dir)
        return;
    const currentDir = get(dirsStore);
    if (currentDir.join("/") || "/" === newFile.dir) {
        const files = get(filesStore);
        const index = files.findIndex((f) => f.filename === oldFile.filename);
        if (index >= 0) {
            files[index] = newFile;
            filesStore.set(files);
        }
    }
}
export function deleteFile(file) {
    if (!file.dir)
        return;
    const currentDir = get(dirsStore);
    if (currentDir.join("/") || "/" === file.dir) {
        const files = get(filesStore);
        const index = files.findIndex((f) => f === file);
        if (index >= 0) {
            files.splice(index, 1);
            filesStore.set(files);
        }
    }
}
export const loopStore = writable(null);
export const titleStore = writable(null);
export function resetTitle() {
    const site = get(siteStore);
    if (site && site.name) {
        titleStore.set(site.name);
    }
    else {
        titleStore.set(constants.app_name);
    }
}
export const uploadTaskStore = writable([]);
export function updateTask(file, status, progress) {
    const tasks = get(uploadTaskStore);
    const index = tasks.findIndex((t) => t.file === file);
    if (index >= 0) {
        tasks[index].status = status;
        tasks[index].progress = progress;
        uploadTaskStore.set(tasks);
    }
}
export const workerStore = writable([]);
export function pushWorker(worker) {
    const newWorkers = get(workerStore);
    newWorkers.push(worker);
    workerStore.set(newWorkers);
}
export function removeWorker(worker) {
    const workers = get(workerStore);
    const newWorkers = workers.filter((w) => w !== worker);
    workerStore.set(newWorkers);
}
export function terminateWorkers() {
    const workers = get(workerStore);
    for (const worker of workers) {
        worker.terminate();
    }
    workerStore.set([]);
}
