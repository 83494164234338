export var EFileType;
(function (EFileType) {
    EFileType["Code"] = "Code";
    EFileType["Dir"] = "Dir";
    EFileType["Image"] = "Image";
    EFileType["Pdf"] = "Pdf";
    EFileType["Music"] = "Music";
    EFileType["Text"] = "Text";
    EFileType["Video"] = "Video";
    EFileType["Unknown"] = "Unknown";
})(EFileType || (EFileType = {}));
export var EIconType;
(function (EIconType) {
    EIconType["success"] = "success";
    EIconType["error"] = "error";
    EIconType["profile"] = "profile";
    EIconType["add"] = "add";
    EIconType["close"] = "close";
    EIconType["up"] = "up";
    EIconType["down"] = "down";
    EIconType["folder"] = "folder";
    EIconType["code"] = "code";
    EIconType["pdf"] = "pdf";
    EIconType["image"] = "image";
    EIconType["text"] = "text";
    EIconType["music"] = "music";
    EIconType["video"] = "video";
    EIconType["back"] = "back";
    EIconType["forward"] = "forward";
    EIconType["expand"] = "expand";
    EIconType["shuffle"] = "shuffle";
    EIconType["loop"] = "loop";
    EIconType["repeat"] = "repeat";
    EIconType["unknown"] = "unknown";
    EIconType["closecircle"] = "closecircle";
    EIconType["link"] = "link";
    EIconType["play"] = "play";
    EIconType["play_forward"] = "play_forward";
    EIconType["play_back"] = "play_back";
    EIconType["play_speed"] = "play_speed";
    EIconType["close_caption"] = "close_caption";
    EIconType["play_next"] = "play_next";
    EIconType["list"] = "list";
    EIconType["search"] = "search";
    EIconType["eyeoff"] = "eyeoff";
})(EIconType || (EIconType = {}));
export var EIconColor;
(function (EIconColor) {
    EIconColor["green"] = "green";
    EIconColor["red"] = "red";
    EIconColor["gray"] = "gray";
    EIconColor["black"] = "black";
    EIconColor["white"] = "white";
    EIconColor["blue"] = "blue";
    EIconColor["yellow"] = "yellow";
    EIconColor["pink"] = "pink";
})(EIconColor || (EIconColor = {}));
export var ELoopMethod;
(function (ELoopMethod) {
    ELoopMethod["repeat"] = "repeat";
    ELoopMethod["shuffle"] = "shuffle";
    ELoopMethod["loop"] = "loop";
})(ELoopMethod || (ELoopMethod = {}));
export var EUploadStatus;
(function (EUploadStatus) {
    EUploadStatus["waiting"] = "waiting";
    EUploadStatus["preparing"] = "preparing";
    EUploadStatus["uploading"] = "uploading";
    EUploadStatus["finishing"] = "finishing";
    EUploadStatus["success"] = "success";
    EUploadStatus["failed"] = "failed";
})(EUploadStatus || (EUploadStatus = {}));
export var ECopyMoveTaskStatus;
(function (ECopyMoveTaskStatus) {
    ECopyMoveTaskStatus["Pending"] = "Pending";
    ECopyMoveTaskStatus["InProgress"] = "InProgress";
    ECopyMoveTaskStatus["Success"] = "Success";
    ECopyMoveTaskStatus["Failed"] = "Failed";
})(ECopyMoveTaskStatus || (ECopyMoveTaskStatus = {}));
