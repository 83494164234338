import { userStore } from './store';
export async function get(url, dataType = "json") {
    let response;
    try {
        response = await fetch(url);
    }
    catch (e) {
        throw e;
    }
    if (!response.ok) {
        throw new Error(response.status.toString());
    }
    return dataType === "json" ? await response.json() : dataType === "text" ? await response.text() : dataType === "blob" ? await response.blob() : response;
}
export async function getRange(url, start, end = 0) {
    let response;
    let rangeHeader = "bytes=" + start + "-";
    if (end > 0) {
        rangeHeader += end;
    }
    try {
        response = await fetch(url, {
            headers: {
                "range": rangeHeader
            }
        });
    }
    catch (e) {
        throw e;
    }
    if (!response.ok) {
        throw new Error(response.status.toString());
    }
    return await parseRangeResponse(response);
}
export async function post(url, payload, jsonResponse) {
    let response;
    try {
        response = await fetch(url, { body: JSON.stringify(payload), method: 'POST' });
    }
    catch (e) {
        throw e;
    }
    if (!response.ok) {
        throw new Error(response.status.toString());
    }
    return jsonResponse ? await response.json() : await response.text();
}
export async function put(url, payload, jsonResponse) {
    let response;
    try {
        response = await fetch(url, { body: JSON.stringify(payload), method: 'PUT' });
    }
    catch (e) {
        throw e;
    }
    if (!response.ok) {
        throw new Error(response.status.toString());
    }
    return jsonResponse ? await response.json() : await response.text();
}
export async function remove(url, payload, jsonResponse) {
    let response;
    try {
        response = await fetch(url, { body: JSON.stringify(payload), method: 'DELETE' });
    }
    catch (e) {
        throw e;
    }
    if (!response.ok) {
        throw new Error(response.status.toString());
    }
    return jsonResponse ? await response.json() : await response.text();
}
export async function refresh_token() {
    try {
        const response = await fetch("/api/user/refresh");
        const user = await response.json();
        return user;
    }
    catch (e) {
        userStore.set(null);
    }
}
async function parseRangeResponse(response) {
    const blob = await response.blob();
    const range = response.headers.get("content-range");
    const splits = range.split(/\s|-|\//);
    const type = response.headers.get("content-type");
    const partialBlob = {
        blob, start: +splits[1], end: +splits[2], size: +splits[3], type
    };
    return partialBlob;
}
